<template>
    <div class="container" :class="$style.container">
        <PageHeader :title="page.title" :subtitle="page.subtitle" />

        <div class="ui-border-line ui-mb-1" />

        <div v-if="checkEnabledKey('wsong')" :class="$style.question">
            <PageHeader title="원하는 노래가 있으신가요?" titleSize="22px" />

            <div :class="$style.inputbox">
                <input
                    type="text"
                    v-model="form.wsong"
                    placeholder="예) 정인-오르막길, 없어요 추천해주세요 등"
                    required
                />
            </div>
        </div>

        <div v-if="checkEnabledKey('wmcStyle')" :class="$style.question">
            <PageHeader
                title="원하는 진행 스타일을 선택해주세요."
                titleSmall="(복수 선택)"
                titleSize="22px"
            />

            <TagBox>
                <li
                    v-for="(row, key) in $config.constant.mcStyle"
                    :key="key"
                    :class="{
                        on: formHelper.has(form.wmcStyle, row, '|'),
                    }"
                    @click="
                        () => {
                            form.wmcStyle = formHelper.multiple(
                                form.wmcStyle,
                                row,
                                '|'
                            )
                        }
                    "
                >
                    {{ row }}
                </li>
            </TagBox>
        </div>

        <div v-if="checkEnabledKey('playTime')" :class="$style.question">
            <PageHeader
                :title="playTime.title"
                titleSize="22px"
                :subtitle="playTime.subtitle"
            />

            <TagBox>
                <li
                    v-for="(row, key) in formOptions.playTime"
                    :key="key"
                    :class="{
                        on: form.playTime === row,
                    }"
                    @click="() => (form.playTime = row)"
                >
                    {{ row }}
                </li>
                <li
                    :class="{
                        on: formHelper.getEtc(
                            form.playTime,
                            formOptions.playTime
                        ),
                    }"
                    @click="
                        () => showEtcModal('playTime', formOptions.playTime)
                    "
                >
                    직접 입력
                </li>
            </TagBox>
        </div>

        <div v-if="checkEnabledKey('priceStart')" :class="$style.question">
            <PageHeader title="희망하는 금액은 얼마인가요?" titleSize="22px" />

            <div :class="$style.wantedPrice">
                {{ moneyFormat(priceState.value) }}
            </div>

            <slider
                :class="$style.slider"
                :data="priceState.value"
                :min="0"
                :max="priceState.lastIndex"
                :interval="1"
                @cb="priceSliderCb"
            />
        </div>

        <div v-if="checkEnabledKey('wmic')" :class="$style.question">
            <PageHeader
                title="음향 장비는 제공해주시나요? (마이크, 스피커 등)"
                titleSize="22px"
            />

            <TagBox>
                <li
                    v-for="(row, key) in formOptions.wmic"
                    :key="key"
                    :class="{
                        on: form.wmic === row,
                    }"
                    @click="() => (form.wmic = row)"
                >
                    {{ row }}
                </li>
            </TagBox>
        </div>

        <div v-if="checkEnabledKey('detail')" :class="$style.question">
            <PageHeader title="추가로 전달할 내용이 있나요?" titleSize="22px" />

            <div :class="$style.textarea">
                <textarea v-model="form.detail" />
            </div>
        </div>

        <FooterBox
            :single="false"
            rate37
            btnText="닫기"
            :btnCb="() => exit()"
            submitText="견적 요청하기"
            :submitDisabled="!isValidForm"
            :submitCb="() => submit()"
        />

        <Modal
            ref="etcModalRef"
            title="직접 입력해주세요"
            class="etc"
            confirm="등록"
            :confirmCb="etcModal.cb"
        >
            <div slot="body">
                <div class="ui-inputbox etc">
                    <input
                        type="text"
                        v-model="etcModal.value"
                        placeholder="직접 입력해주세요"
                    />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script setup>
import {
    computed,
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    ref,
} from '@vue/composition-api'

import { useCacheStore } from '@/pinia/modules/cache'
import { calcPriceArr, getHasWantPrice } from '@/lib/offerPrice'

import FooterBox from '@/components/common/FooterBox.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import TagBox from '@/components/common/TagBox.vue'
import Modal from '@/components/common/Modal.vue'
import Slider from '@/components/common/Slider.vue'

const formHelper = {
    multiple(target: string, value: string, separator: RegExp | string = ',') {
        const memo = new Set(target.split(separator).filter((val) => !!val))

        if (memo.has(value)) {
            memo.delete(value)
        } else {
            memo.add(value)
        }

        return Array.from(memo).join(separator)
    },
    has(target: string, value: string, separator: RegExp | string = ',') {
        return new Set(target.split(separator).filter((val) => !!val)).has(
            value
        )
    },
    getEtc(
        target: string,
        origin: string[] = [],
        separator: RegExp | string = ','
    ) {
        const originSet = new Set(origin)
        return (
            target
                .split(separator)
                .filter((val) => !!val)
                .find((val) => !originSet.has(val)) || ''
        )
    },
}

export default defineComponent({
    name: 'PostRelatedOffer',
    components: {
        FooterBox,
        PageHeader,
        TagBox,
        Modal,
        Slider,
    },
    setup() {
        const vm = getCurrentInstance()

        const etcModalRef = ref()

        const cacheStore = useCacheStore()

        const form = reactive({
            wsong: '',
            wmcStyle: '',
            playTime: '',
            priceStart: 0,
            priceEnd: 0,
            wmic: '',
            detail: '',
        })

        const memoState = reactive({
            postedOfferData: {},
        })

        const etcModal = reactive({
            value: '',
            cb: (modal) => {
                modal?.hide?.()
            },
        })

        const priceState = reactive({
            value: 0,
            lastIndex: 0,
            arr: [],
        })

        const query = computed(() => ({
            /**
             * @type {'노래'|'MC'}
             */
            mode: vm.proxy.$route.query.mode || 'MC',
        }))

        const page = computed((): {
            title: string,
            subtitle: string,
        } => {
            switch (query.value.mode) {
                case 'MC':
                    return {
                        title: `10초 만에\n사회자 견적도 알아보세요! ⚡️`,
                        subtitle:
                            '몇가지만 입력하면 사회자도 빠르게 알아볼 수 있어요.',
                    }

                case '노래':
                default:
                    return {
                        title: `10초 만에\n축가 견적도 알아보세요! ⚡️`,
                        subtitle:
                            '몇가지만 입력하면 축가도 빠르게 알아볼 수 있어요.',
                    }
            }
        })

        const playTime = computed((): {
            title: string,
            subtitle: string,
        } => {
            switch (query.value.mode) {
                case 'MC':
                    return {
                        title: '전문가는 몇 시간동안 필요한가요?',
                        subtitle:
                            '다른 상황이 있다면? 직접 입력하세요. (ex: 2부만 진행)',
                    }

                case '노래':
                default:
                    return {
                        title: '원하는 곡수를 선택하세요.',
                        subtitle:
                            '축가는 1곡이 기본이고, 곡수에 따라 견적이 달라져요.',
                    }
            }
        })

        const formOptions = computed(() => {
            return {
                get playTime() {
                    switch (query.value.mode) {
                        case 'MC':
                            return ['1부 예식', '2부 예식']

                        case '노래':
                        default:
                            return ['1곡', '2곡', '3곡']
                    }
                },
                wmic: ['제공', '전문가가 준비'],
            }
        })

        const isValidForm = computed(() => {
            const requiredKeys = []

            switch (query.value.mode) {
                case 'MC':
                    requiredKeys.push('wmcStyle')
                    requiredKeys.push('playTime')
                    // requiredKeys.push('priceStart')
                    // requiredKeys.push('priceEnd')
                    break

                case '노래':
                    requiredKeys.push('wsong')
                    requiredKeys.push('playTime')
                    // requiredKeys.push('priceStart')
                    // requiredKeys.push('priceEnd')
                    requiredKeys.push('wmic')
                    break
            }

            return requiredKeys.every((key) => !!form[key])
        })

        onMounted(() => {
            initialize()
        })

        const initialize = () => {
            if (!query.value.mode) {
                vm.proxy.$router.push('/home')
                return
            }

            const postedOfferData = (() => {
                try {
                    return (
                        JSON.parse(
                            cacheStore.getItem('pageCache:PostRelatedOffer')
                        ) || {}
                    )
                } catch {
                    return {}
                }
            })()

            const { _wantPrice, priceStart } = postedOfferData
            const hasWantPrice = getHasWantPrice(postedOfferData)
            const wantPrice = (hasWantPrice && _wantPrice) || 0

            const [priceArr, defaultPriceStart] = calcPriceArr(wantPrice)

            priceState.value = reversePrice(
                priceStart || defaultPriceStart,
                priceArr
            )
            priceState.lastIndex = priceArr.length - 1
            priceState.arr = priceArr

            form.priceStart = priceStart
            form.priceEnd = priceStart

            memoState.postedOfferData = postedOfferData
        }

        const showEtcModal = (
            key,
            origin: string[] = [],
            separator: RegExp | string = ','
        ) => {
            const target = form[key]

            const etc = formHelper.getEtc(target, origin, separator)

            etcModal.value = etc
            etcModal.cb = (modal) => {
                modal?.hide?.()

                const memo = new Set(target.split(separator))

                memo.delete(etc)
                memo.add(etcModal.value)

                form[key] = Array.from(memo)
                    .filter((val) => !!val)
                    .join(separator)
            }

            etcModalRef.value.show?.()
        }

        const checkEnabledKey = (key) => {
            switch (query.value.mode) {
                case 'MC':
                    return new Set([
                        'wmcStyle',
                        'playTime',
                        'priceStart',
                        'detail',
                        //
                    ]).has(key)

                case '노래':
                    return new Set([
                        'wsong',
                        'playTime',
                        'priceStart',
                        'wmic',
                        'detail',
                        //
                    ]).has(key)

                default:
                    return false
            }
        }

        const priceSliderCb = (val) => {
            form.priceStart = resultPrice(val)
            form.priceEnd = resultPrice(val)
            priceState.value = val
            vm.proxy.$forceUpdate()
        }

        const resultPrice = (key = 0) => {
            return priceState.arr?.[key] || 0
        }

        const reversePrice = (price, priceArr = priceState.arr) => {
            const key = priceArr.indexOf(parseFloat(price, 10))

            if (key === -1) {
                return 0
            }

            return key
        }

        const moneyFormat = (priceKey = 0) => {
            let postmoney = '원'
            if (priceKey === priceState.lastIndex) {
                postmoney += '+'
            }
            return vm.proxy.$lib.moneyFormat(resultPrice(priceKey)) + postmoney
        }

        const exit = () => {
            vm.proxy.$router.push('/home')
        }

        const submit = () => {
            const payload = {
                genre: query.value.mode,
            }

            const commonKeySet = new Set([
                'startOfferFlag',
                'playDate',
                'location',
                'address',
                'geoX',
                'geoY',
                'eventType',
                'wage',
                'wgender',
                'wtransCost',
            ])

            for (const key of Object.keys(memoState.postedOfferData)) {
                if (!commonKeySet.has(key)) {
                    continue
                }

                const value = memoState.postedOfferData[key]

                payload[key] = value
            }

            vm.proxy.$store.commit('setPostOffer', {
                ...payload,
                ...form,
            })

            vm.proxy.$store
                .dispatch('postOffer/submit', vm.proxy)
                .then((resultData) => {
                    if (resultData.success) {
                        vm.proxy.$toast('견적 요청 완료! 곧 견적이 도착할거예요.')
                        vm.proxy.$router.push('/home')
                    } else {
                        vm.proxy.$alert(resultData)
                    }
                })
                .catch(log.error)
        }

        return {
            formHelper,
            etcModalRef,
            form,
            etcModal,
            priceState,
            page,
            playTime,
            formOptions,
            isValidForm,
            showEtcModal,
            checkEnabledKey,
            priceSliderCb,
            moneyFormat,
            exit,
            submit,
        }
    },
})
</script>

<style lang="scss" module>
@import '@/assets/css/constant.scss';

.container {
    padding-top: 60px;
}

.question {
    margin-bottom: 30px;

    .wantedPrice {
        margin-top: -10px;
        color: $color-deepLavender;
        font-size: 20px;
    }

    .slider {
        margin-top: 10px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .textarea {
        margin-top: -15px;
        position: relative;

        textarea {
            padding: 1em;
            width: 100%;
            font-size: 2rem;
            line-height: 1.5;
            height: 7rem;
            border: 1px solid #cecece;
            border-radius: 5px;
            min-height: 14rem;
        }
    }

    .inputbox {
        margin-top: -15px;
        position: relative;

        input {
            padding: 1em;
            width: 100%;
            font-size: 2rem;
            line-height: 2rem;
            border: 1px solid #cecece;
            border-radius: 5px;
        }
    }
}
</style>
