export function calcPriceArr(wantPrice: number = 0) {
    let defaultPriceStart = 0
    let defaultPriceEnd = 1000000
    const priceArr = [wantPrice]
    const priceConditionArr = [
        {
            min: 0,
            max: 1000000,
            interval: 50000,
        },
        {
            min: 1000000,
            max: 5000000,
            interval: 500000,
        },
        {
            min: 5000000,
            max: 10000000,
            interval: 1000000,
        },
        {
            min: 10000000,
            max: 50000000,
            interval: 5000000,
        },
    ]

    priceConditionArr.forEach(({ min, max, interval }) => {
        if (wantPrice >= max) {
            return
        }

        calc(min, max, interval)
    })

    if (wantPrice) {
        defaultPriceStart = wantPrice

        for (let i = 5; i >= 0; i--) {
            if (priceArr[i]) {
                defaultPriceEnd = priceArr[i]
                break
            }
        }
    }

    return [priceArr, defaultPriceStart, defaultPriceEnd]

    function calc(min, max, interval) {
        const total = (max - min) / interval
        for (let index = 1; index <= total; index++) {
            const price = min + index * interval

            if (wantPrice < price) {
                priceArr.push(price)
            }
        }
    }
}

export function getHasWantPrice(postOffer) {
    const required = [
        postOffer?.mode === 'direct',
        postOffer.artistidx > 0,
        postOffer?._wantPrice !== null,
    ]

    return required.every((val) => val)
}
