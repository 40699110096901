// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RelatedOffer_container_2BBIy {\n  padding-top: 60px;\n}\n.RelatedOffer_question_3nfiz {\n  margin-bottom: 30px;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_wantedPrice_3NSRa {\n  margin-top: -10px;\n  color: #a059b6;\n  font-size: 20px;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_slider_23RMD {\n  margin-top: 10px;\n  margin-left: -15px;\n  margin-right: -15px;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_textarea_2FyJO {\n  margin-top: -15px;\n  position: relative;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_textarea_2FyJO textarea {\n  padding: 1em;\n  width: 100%;\n  font-size: 2rem;\n  line-height: 1.5;\n  height: 7rem;\n  border: 1px solid #cecece;\n  border-radius: 5px;\n  min-height: 14rem;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_inputbox_2FYtb {\n  margin-top: -15px;\n  position: relative;\n}\n.RelatedOffer_question_3nfiz .RelatedOffer_inputbox_2FYtb input {\n  padding: 1em;\n  width: 100%;\n  font-size: 2rem;\n  line-height: 2rem;\n  border: 1px solid #cecece;\n  border-radius: 5px;\n}", ""]);
// Exports
exports.locals = {
	"container": "RelatedOffer_container_2BBIy",
	"question": "RelatedOffer_question_3nfiz",
	"wantedPrice": "RelatedOffer_wantedPrice_3NSRa",
	"slider": "RelatedOffer_slider_23RMD",
	"textarea": "RelatedOffer_textarea_2FyJO",
	"inputbox": "RelatedOffer_inputbox_2FYtb"
};
module.exports = exports;
